import React, { Component } from "react";
import axios from "axios";
import noPhoto from "../assets/user.png";
import loading from "../assets/loading.gif";
import "./PersonDetails.css";
import { requestToView } from "../helper";
import DetailColumn from "../DetailColumn/DetailColumn";
import { ISIS_TOKEN } from "../env";

class PersonDetails extends Component {
  state = {
    loaded: false,
    data: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const config = {
      headers: { "Isis-Token": ISIS_TOKEN }
    };
    axios
      .get(
        `https://appcodata.pb.gov.br/isis-api/api/v1/delegaciaonline/pessoa-desaparecida-detalhe?id=${id}`,
        config
      )
      .then(res =>
        this.setState({
          data: res.data.result,
          loaded: true
        })
      )
      .catch(err => console.log(err));
  }

  render() {
    const { foto } = this.state.data;
    const { loaded } = this.state;
    const imageBase64 = `base64, ${foto}`;
    const infoColumns = requestToView(this.state.data);

    return (
      <div className="container">
        {loaded ? (
          <div className="card">
            <div className="columns estilo-colunas">
              <div className="column">
                <figure className="image notification-image">
                  {foto ? (
                    <img
                      src={"data:image/jpeg;" + imageBase64}
                      alt="Desaparecido"
                      className="image"
                    />
                  ) : (
                    <img src={noPhoto} alt="Sem foto" />
                  )}
                </figure>
              </div>
              {infoColumns.map((column, index) => {
                return <DetailColumn key={index.toString()} column={column} />;
              })}
            </div>
          </div>
        ) : (
          <figure className="image container is-128x128">
            <img src={loading} alt="Carregando" />
          </figure>
        )}
      </div>
    );
  }
}

export default PersonDetails;
