import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <section className="hero is-primary has-background-link">
      <figure>
        <img src="../../public/favicon.ico" alt="" />
      </figure>

      <div className="hero-body">
        <Link to={`/desaparecidos`}>
          <h1 className="title">Delegacia Online</h1>
          <h2 className="subtitle">
            Secretaria de Estado da Segurança e da Defesa Social
          </h2>
        </Link>
      </div>
    </section>
  );
};

export default Header;
