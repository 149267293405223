import React from "react";

const Row = ({ label, informacao }) => {
  return (
    <tr>
      <td className="label-info">{label}</td>
      <td>{informacao}</td>
    </tr>
  );
};

export default Row;
