import React from "react";
import noPhoto from "../assets/user.png";
import { Link } from "react-router-dom";
import "./Card.css";


const Card = props => {
  const { id, nome, dataFato, foto, tipoCard } = props;
  const imageBase64 = `base64, ${foto}`;

  return (
      <div className="column is-one-third custom-card">
        <Link to={`/desaparecidos/detalhes/${id}`} className="title is-6">
          <div className="is-horizontal columns has-background-white-ter card-style box">
            <figure className="image notification-image">
              {foto ? (
                  <img
                      src={"data:image/jpeg;" + imageBase64}
                      alt="Desaparecido"
                  />
              ) : (
                  <img src={noPhoto} alt="Sem foto" />
              )}
            </figure>
            <div className="card-content">
              {nome}
              {tipoCard === "desaparecidos" ? (
                  <div className="content">Desaparecido desde: {dataFato}</div>
              ) : (
                  <div className="content">Encontrado em: {dataFato}</div>
              )}
            </div>
          </div>
        </Link>
      </div>
  );

};

export default Card;
