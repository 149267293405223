import React from "react";
import Row from "../Row/Row";

const DetailColumn = ({ column }) => {
  const formattedKey = label => {
    return label.replace(" ", "-").toLowerCase();
  };
  return (
    <div className="column">
      <div className="card-content">
        <table className="table">
          <tbody>
            {column.length > 0 &&
              column.map(info => {
                return (
                  info.display && (
                    <Row
                      key={formattedKey(info.label)}
                      label={info.label}
                      informacao={info.informacao}
                    />
                  )
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailColumn;
