export const requestToView = result => {
  let view = [];
  const entries = Object.entries(result);

  for (const [key, value] of entries) {
    const newObj = {};
    if (key === "foto" || key === "id") {
      newObj.display = false;
    } else {
      newObj.display = true;
    }

    newObj.propriedade = key;
    newObj.label = formatLabel(key);
    newObj.informacao = value;

    view = [...view, newObj];
  }

  const fatoOcorridoIndex = view.findIndex(
    element => element.propriedade === "descricao_do_ocorrido"
  );

  if (fatoOcorridoIndex >= 0) {
    moveItem(view, fatoOcorridoIndex, view.length);
  }

  view = chunk(view, 12);

  return view;
};

function chunk(a, l) {
  return new Array(Math.ceil(a.length / l))
    .fill()
    .map((_, n) => a.slice(n * l, n * l + l));
}

function formatLabel(key) {
  const trimmedKey = key.replace(/_/g, " ");
  const result = trimmedKey
    .split(" ")
    .map(t => t.substring(0, 1).toUpperCase() + t.substring(1))
    .join(" ");

  return result;
}

function moveItem(array, from, to) {
  // remove `from` item and store it
  var f = array.splice(from, 1)[0];
  // insert stored item into position `to`
  array.splice(to, 0, f);
}
