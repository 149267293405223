import React, { Component } from "react";
import Card from "../Card/Card";
import axios from "axios";
import loading from "../assets/loading.gif";
import moment from "moment";
import _ from "lodash";
import { ISIS_TOKEN } from "../env";

class CardSection extends Component {
  state = {
    loaded: false,
    data: [],
    categoria: "Selecione"
  };

  componentDidMount() {
    const config = {
      headers: { "Isis-Token": ISIS_TOKEN }
    };
    const { apiString } = this.props;

    axios
      .get(
        `https://appcodata.pb.gov.br/isis-api/api/v1/delegaciaonline/pessoas-${apiString}`,
        config
      )
      .then(res =>
        this.setState({
          data: res.data.result.data,
          loaded: true
        })
      )
      .catch(err => console.log(err));
  }

  convertTimestampToDate = dataFatoOcorrido => {
    return moment(dataFatoOcorrido).format("DD/MM/YYYY");
  };

  formatName = fullName => {
    const fullNameArray = _.split(_.toLower(fullName), " ");

    const capitalizeFirstLetter = fullNameArray.map(name => {
      return _.startCase(name);
    });

    const formattedName = _.join(capitalizeFirstLetter, " ");

    return formattedName;
  };

  handleSelect = evt => {
    this.setState({loaded: false})
    const { apiString } = this.props;

    const config = {
      headers: { "Isis-Token": ISIS_TOKEN }
    };

    this.setState({ categoria: evt.target.value });

    axios
      .get(
        `https://appcodata.pb.gov.br/isis-api/api/v1/delegaciaonline/pessoas-${apiString}`,
        config
      )
      .then(res => {
        const resultado = res.data.result.data.filter(pessoa => {
          if (this.state.categoria === "no_photo") {
            return pessoa.foto === null;
          } else if (this.state.categoria === "photo") {
            return pessoa.foto !== null;
          } else {
            return pessoa;
          }
        });
        this.setState({
          data: resultado,
          loaded: true
        });
      })
      .catch(err => console.log(err));
  };

  render() {
    const { apiString } = this.props;
    const { loaded, data } = this.state;
    return (
      <div className="section">
        <div className="container is-fluid">
          <div className="field">
            <div className="control">
              <div className="select">
                <select
                  id="selectCategoria"
                  onChange={this.handleSelect}
                  value={this.state.categoria}
                >
                  <option>Selecione</option>
                  <option value="photo" key="photo">
                    Apenas pessoas com foto
                  </option>
                  <option value="no_photo" key="no_photo">
                    Apenas pessoas sem foto
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="columns is-multiline">
            {loaded ? (
              data.map(pessoa => (
                <Card
                  key={pessoa.id}
                  nome={this.formatName(pessoa.nome)}
                  foto={pessoa.foto}
                  id={pessoa.id}
                  dataFato={
                    apiString === "desaparecidas"
                      ? this.convertTimestampToDate(
                          pessoa.data_do_fato_ocorrido
                        )
                      : this.convertTimestampToDate(
                          pessoa.data_momento_registro_pessoa_encontrada
                        )
                  }
                  tipoCard={
                    apiString === "desaparecidas"
                      ? "desaparecidos"
                      : "encontrados"
                  }
                />
              ))
            ) : (
              <figure className="image container is-128x128">
                <img src={loading} alt="Carregando" />
              </figure>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CardSection;
