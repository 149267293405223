import React from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <NavLink
              to="/desaparecidos"
              className="navbar-item"
              activeClassName="active-menu"
            >
              Desaparecidos
            </NavLink>
            <NavLink
              to="/encontrados"
              className="navbar-item"
              activeClassName="active-menu"
            >
              Encontrados
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
