import React, { Component } from "react";
import CardSection from "./CardSection/CardSection";
import Header from "../src/Header/Header";
import Navbar from "./Navbar/Navbar";
// import Footer from "./Footer/Footer";
import { Route } from "react-router-dom";
import "./App.css";
import PersonDetails from "./PersonDetails/PersonDetails";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Navbar />
        <Route exact path="/" render={() => <CardSection apiString="desaparecidas" />} />
        <Route exact path="/desaparecidos" render={() => <CardSection apiString="desaparecidas" />} />
        <Route exact path="/desaparecidos/detalhes/:id" render={props => <PersonDetails {...props} apiString="desaparecidas"/>} />
        <Route exact path="/encontrados" render={() => <CardSection apiString="encontradas" />} />
        {/*<Footer />*/}
      </div>
    );
  }
}

export default App;
